@import "../../variables.scss";

.error404-content {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
    padding-top: 40vh;
}

.error404-texto {
    font-size: 30px; 
    text-align: center;
    margin-bottom: 40px;
    color: $amarillo;
    font-family: 'Times New Roman', Times, serif; 
}
@import "./variables.scss";

html {
  background-color: $negro-fondo;
  height: 100vh;
  margin: 0;
  scrollbar-width: thin !important;
  scrollbar-color: $amarillo $negro-fondo;  
}

body {
  background-color: transparent !important;
  height: 100vh;
  margin: 0;
}

body::-webkit-scrollbar {
  width: 20px;
}
body::-webkit-scrollbar-track {
  background: $negro-fondo;
}
body::-webkit-scrollbar-thumb {
  background-color: $amarillo;
  border-radius: 20px;
  border: 8px solid $negro-fondo;  /* creates padding around scroll thumb */
}

@font-face {
  font-family: 'sun';
  src: url('./assets/fonts/sun.otf') format('opentype');
}
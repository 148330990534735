.botonhome-svg-home {
    width: 40px;
    height: 40px;
    animation: botonhome-svg-small 120ms forwards;
}
.botonhome-svg-home:hover {
    cursor: pointer;
    animation: botonhome-svg-big 120ms forwards;
}
@keyframes botonhome-svg-big {
    from { transform: scale(1,1)  }
    to { transform: scale(1.5,1.5)  }
}
@keyframes botonhome-svg-small {
    0%   { transform: scale(1.5,1.5) }
    100% { transform: scale(1,1)  }
}
@import "../../variables.scss";

.home-bg {
    background-image: url("../../assets/imagenes/background-desktop.jpg");
    height: 100%; 
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.home-content {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
}

.home-titulo {
    font-family: 'sun';
    font-size: 72px;
    text-align: center;
    margin-top: 106px;
    margin-bottom: 30px;
    color: $amarillo;
}

.home-subtitulo {
    font-style: italic; 
    font-family: 'Times New Roman', Times, serif; 
    font-size: 24px; 
    text-align: center;
    margin-bottom: 40px;
    color: $amarillo;
}

.home-boton {
    width: 180px;
    margin-bottom: 20px;
    text-align: center;
}

.home-boton-pinturas {
    width: 180px;
    margin-bottom: 45px;
    text-align: center;
}

.home-contacto1 {
    margin-top: 34px;
    font-style: italic; 
    font-family: 'Times New Roman', Times, serif; 
    font-size: 16px; 
    text-align: center;
    color: $amarillo;
}

.home-contacto2 {
    font-family: 'Times New Roman', Times, serif; 
    font-size: 18px; 
    text-align: center;
    margin-bottom: 160px;
    color: $amarillo;
}

@media screen and (max-width: 575px) {
    .home-bg {
        background-image: url("../../assets/imagenes/background-movil.jpg");
    }
}
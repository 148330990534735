@import "../../variables.scss";

.infop-img-arnold {
    border-radius: 50%;
    margin-bottom: 40px;
}
.infop-div-arnold {
    position: relative;
}
.infop-border-arnold {
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border-color: $amarillo;
    border-style: solid;
    border-width: 3px;
    position: absolute;
    top: 0;
    z-index: -1;
    animation-name: border-arnold;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
}

@keyframes border-arnold {
    from {
        transform: rotate(0deg) translate(10px) rotate(0deg);
    }
    to {
        transform: rotate(360deg) translateX(10px) rotate(-360deg);
    }
}

.infop-content {
    display: flex;
    margin: auto;
    flex-direction: column;
    align-items: center;
}

.infop-titulo {
    font-family: 'sun';
    font-size: 72px;
    text-align: center;
    margin-top: 106px;
    margin-bottom: 30px;
    color: $amarillo;
}

.infop-subtitulo {
    font-size: 30px; 
    text-align: center;
    margin-bottom: 40px;
    color: $amarillo;
    font-family: 'Times New Roman', Times, serif; 
}
.infop-comisiones {
    font-size: 26px; 
    text-align: center;
    margin-bottom: 40px;
    color: $amarillo;
    font-family: 'Times New Roman', Times, serif; 
}
.infop-fecha {
    font-size: 20px; 
    text-align: center;
    margin-bottom: 10px;
    color: $amarillo;
    font-family: 'Times New Roman', Times, serif; 
}

.infop-boton {
    width: 180px;
    margin-bottom: 10px;
    text-align: center;
}

.infop-div-texto {
    margin-left: 27%;
    margin-right: 27%;
    text-align: justify;
    color: $amarillo;
    font-family: 'Times New Roman', Times, serif; 
}
.infop-texto {
    font-size: 20px;
    margin-bottom: 20px;
}
.infop-texto-bullet {
    font-size: 22px;
    margin-top: 60px;
}

.infop-firma1 {
    margin-top: 160px;
    font-size: 20px; 
    text-align: right;
    margin-bottom: 0px;
}
.infop-firma2 {
    font-size: 26px; 
    text-align: right;
    font-style: italic; 
    margin-bottom: 0px;
}
.infop-img-weropinturas {
    margin-top: -160px;
    margin-left: -400px;
    margin-bottom: 50px;
    z-index: -1;
}
.infop-fondo {
    margin-bottom: 160px;
}

@media screen and (max-width: 575px) {
    .infop-div-texto {
        margin-left: 4%;
        margin-right: 4%;
    }
    .infop-titulo {
        font-size: 48px;
    }
    .infop-firma1 {
        margin-top: 80px;
    }
    .infop-firma2 {
        margin-bottom: 20px;
    }
    .infop-img-weropinturas {
        margin-top: 0;
        margin-left: 0;
        margin-bottom: 40px;
        width: 360px;
    }
}
@media screen and (min-width: 576px) and (max-width: 1200px) {
    .infop-div-texto {
        margin-left: 14%;
        margin-right: 14%;
    }
    .infop-titulo {
        font-size: 54px;
    }
}
